import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Layout from 'components/Layout'

import theme from 'styles/theme'

import Vector1 from 'assets/vectors/not-found-vector1.svg'
import Vector2 from 'assets/vectors/not-found-vector2.svg'

const Container = styled.div`
  position: relative;
  width: 615px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto;

  p {
    max-width: 618px;
    ${theme.fonts.largeText};
    color: ${theme.colors.neutralDarkest};
    text-align: center;
    margin-bottom: 24px;
  }

  span {
    ${theme.fonts.largeBold};
    color: ${theme.colors.neutralDarkest};
  }

  @media (max-width: ${theme.breakPoints.biggestMobile}) {
    width: 100%;
    height: fit-content;
    padding: 28px;
    margin: 28px auto;

    p {
      ${theme.fonts.mediumText};
    }

    span {
      ${theme.fonts.mediumTextBold};
    }
  }
`

const Title = styled.h1`
  position: relative;
  font-size: 250px;
  line-height: 305px;

  color: transparent;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: ${theme.colors.primaryDarkest};

  @media (max-width: ${theme.breakPoints.biggestMobile}) {
    font-size: 100px;
    line-height: 91px;
    -webkit-text-stroke-width: 2px;
  }
`

const Subitle = styled.h3`
  color: ${theme.colors.primaryDarkest};
  margin-bottom: 30px;

  @media (max-width: ${theme.breakPoints.biggestMobile}) {
    ${theme.fonts.heading4};
    text-align: center;
    margin-top: 20px;
  }
`

const Amoeba1 = styled(Vector1)`
  position: absolute;
  right: -280px;

  @media (max-width: ${theme.breakPoints.smallTablet}) {
    right: -150px;
  }
  @media (max-width: ${theme.breakPoints.biggestMobile}) {
    display: none;
  }
`

const Amoeba2 = styled(Vector2)`
  position: absolute;
  left: -203px;
  top: 27px;

  @media (max-width: ${theme.breakPoints.biggestMobile}) {
    display: none;
  }
`

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Layout css={{ backgroundColor: theme.colors.tertiaryDarkest }}>
      <Container>
        <Amoeba1 />
        <Amoeba2 />
        <Title>404</Title>
        <Subitle>Ops! Algo de errado aconteceu.</Subitle>
        <p>
          Por alguma razão nossa página não pode ser acessada agora. Não se
          preocupe! Enquanto você não acesso o site pode falar com a gente no
        </p>
        <span>moebius@moebius.org.br</span>
      </Container>
    </Layout>
  </ThemeProvider>
)

export default NotFoundPage
